import type { FC } from 'react'
import React from 'react'

import type { IconVoxiProps } from './IconVoxi.types'
import * as Styled from './styles/IconVoxi.style'

import { nonThemeableRules } from '../../ruleSet'

const IconVoxi: FC<IconVoxiProps> = ({
  size = nonThemeableRules.defaultSize as number as IconVoxiProps['size'],
  isResponsive = false,
}: IconVoxiProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.835 104.602h89.204V15.398H15.835v89.204ZM10.437 110h100.001V10h-100v100ZM38.77 52.346l-8.209-22.22h6.052l5.044 15.098h.03l5.284-15.097h5.795l-8.2 22.22H38.77Zm37.72 37.53h5.413V67.663H76.49v22.215Zm-23.717.001-7.64-11.26 7.046-10.95H45.93l-3.924 6.551-4.401-6.556h-6.482l7.091 10.463-7.647 11.752h6.249l4.574-7.282 4.902 7.282h6.481Zm26.43-42.793c-3.211 0-5.757-2.63-5.757-5.874 0-3.244 2.546-5.874 5.757-5.874 3.21 0 5.756 2.63 5.756 5.874 0 3.244-2.546 5.874-5.757 5.874Zm-11.1-5.874c0 6.375 4.97 11.099 11.1 11.099S90.3 47.585 90.3 41.209c0-6.375-4.969-11.099-11.099-11.099s-11.099 4.724-11.099 11.1Z'
          fill='#000'
          fillRule='evenodd'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconVoxi
