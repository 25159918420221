import type { FC } from 'react'
import React from 'react'

import type { IconNetflixNProps } from './IconNetflixN.types'
import * as Styled from './styles/IconNetflixN.style'

import { nonThemeableRules } from '../../ruleSet'

const IconNetflixN: FC<IconNetflixNProps> = ({
  size = nonThemeableRules.defaultSize as number as IconNetflixNProps['size'],
  isResponsive = false,
}: IconNetflixNProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10 42c0-11.201 0-16.802 2.18-21.08a19.999 19.999 0 0 1 8.74-8.74C25.198 10 30.8 10 42 10h36c11.201 0 16.802 0 21.08 2.18a19.997 19.997 0 0 1 8.74 8.74C110 25.198 110 30.8 110 42v36c0 11.201 0 16.802-2.18 21.08a19.995 19.995 0 0 1-8.74 8.74C94.802 110 89.2 110 78 110H42c-11.201 0-16.802 0-21.08-2.18a19.997 19.997 0 0 1-8.74-8.74C10 94.802 10 89.2 10 78V42Z'
          fill='#000'
        />
        <path
          d='M65.945 25.047 65.917 40.7l-.027 15.654-1.288-3.633a.148.148 0 0 1-.003-.008l-1.664 34.85a1179.21 1179.21 0 0 0 2.523 7.098c.013.013.945.069 2.072.125a161.7 161.7 0 0 1 10.854.93c.743.093 1.383.14 1.421.106.039-.034.06-15.973.047-35.42l-.024-35.355H65.945ZM40.833 25v35.392c0 19.466.02 35.411.043 35.435.023.023 1.228-.09 2.678-.253 1.45-.162 3.455-.364 4.454-.449 1.533-.13 6.109-.423 6.639-.427.154 0 .164-.796.186-14.99l.024-14.99 1.107 3.133.391 1.107 1.664-34.841-.572-1.616-2.563-7.245-.092-.256H40.833Z'
          fill='#B1060F'
          fillRule='evenodd'
          stroke='#000'
          strokeWidth='.082'
        />
        <path
          d='M40.833 25v19.867L54.828 81.99l.005-2.283.024-14.99 1.107 3.133c6.163 17.44 9.474 26.79 9.494 26.81.013.013.945.069 2.072.125a161.7 161.7 0 0 1 10.854.93c.743.093 1.383.14 1.421.106.027-.024.044-7.839.049-18.864L65.924 36.91l-.007 3.79-.027 15.654-1.288-3.634c-1.259-3.551-2.099-5.926-7.155-20.22l-2.563-7.245-.092-.256H40.833Z'
          fill='url(#de50e958-ecba-4340-a2e7-861814ef73f1__a)'
          fillRule='evenodd'
        />
        <path
          d='m40.834 25 14.022 39.736v-.018l1.108 3.133c6.163 17.44 9.474 26.79 9.494 26.81.013.013.945.069 2.072.125a161.7 161.7 0 0 1 10.854.93c.74.093 1.376.14 1.42.107L65.89 56.347v.008l-1.288-3.634c-1.259-3.551-2.099-5.926-7.155-20.22l-2.563-7.245-.092-.256H40.834Z'
          fill='#E50914'
          fillRule='evenodd'
        />
        <path
          d='M65.945 25.047 65.917 40.7l-.027 15.654-1.288-3.633a.148.148 0 0 1-.003-.008l-1.664 34.85a1179.21 1179.21 0 0 0 2.523 7.098c.013.013.945.069 2.072.125a161.7 161.7 0 0 1 10.854.93c.743.093 1.383.14 1.421.106.039-.034.06-15.973.047-35.42l-.024-35.355H65.945ZM40.833 25v35.392c0 19.466.02 35.411.043 35.435.023.023 1.228-.09 2.678-.253 1.45-.162 3.455-.364 4.454-.449 1.533-.13 6.109-.423 6.639-.427.154 0 .164-.796.186-14.99l.024-14.99 1.107 3.133.391 1.107 1.664-34.841-.572-1.616-2.563-7.245-.092-.256H40.833Z'
          fill='#B1060F'
          fillRule='evenodd'
          stroke='#000'
          strokeWidth='.082'
        />
        <path
          d='M40.833 25v19.867L54.828 81.99l.005-2.283.024-14.99 1.107 3.133c6.163 17.44 9.474 26.79 9.494 26.81.013.013.945.069 2.072.125a161.7 161.7 0 0 1 10.854.93c.743.093 1.383.14 1.421.106.027-.024.044-7.839.049-18.864L65.924 36.91l-.007 3.79-.027 15.654-1.288-3.634c-1.259-3.551-2.099-5.926-7.155-20.22l-2.563-7.245-.092-.256H40.833Z'
          fill='url(#de50e958-ecba-4340-a2e7-861814ef73f1__b)'
          fillRule='evenodd'
        />
        <path
          d='m40.834 25 14.022 39.736v-.018l1.108 3.133c6.163 17.44 9.474 26.79 9.494 26.81.013.013.945.069 2.072.125a161.7 161.7 0 0 1 10.854.93c.74.093 1.376.14 1.42.107L65.89 56.347v.008l-1.288-3.634c-1.259-3.551-2.099-5.926-7.155-20.22l-2.563-7.245-.092-.256H40.834Z'
          fill='#E50914'
          fillRule='evenodd'
        />
        <defs>
          <radialGradient
            id='de50e958-ecba-4340-a2e7-861814ef73f1__a'
            cx='0'
            cy='0'
            gradientTransform='matrix(8.35737 -2.89367 105.99286 306.12368 59.695 60.004)'
            gradientUnits='userSpaceOnUse'
            r='1'
          >
            <stop />
            <stop offset='1' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='de50e958-ecba-4340-a2e7-861814ef73f1__b'
            cx='0'
            cy='0'
            gradientTransform='matrix(8.35737 -2.89367 105.99286 306.12368 59.695 60.004)'
            gradientUnits='userSpaceOnUse'
            r='1'
          >
            <stop />
            <stop offset='1' stopOpacity='0' />
          </radialGradient>
        </defs>
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconNetflixN
