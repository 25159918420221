import type { FC } from 'react'
import React from 'react'

import type { IconSuperConnectAndroidProps } from './IconSuperConnectAndroid.types'
import * as Styled from './styles/IconSuperConnectAndroid.style'

import { nonThemeableRules } from '../../ruleSet'

const IconSuperConnectAndroid: FC<IconSuperConnectAndroidProps> = ({
  size = nonThemeableRules.defaultSize as number as IconSuperConnectAndroidProps['size'],
  isResponsive = false,
}: IconSuperConnectAndroidProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path d='M59.993 89.61a4.764 4.764 0 1 0 0-9.526 4.764 4.764 0 0 0 0 9.527Z' fill='#BB000B' />
        <path
          d='M59.993 88.323a4.758 4.758 0 0 1-4.712-4.12c-.033.213-.05.429-.051.644a4.764 4.764 0 0 0 9.527 0c0-.215-.018-.43-.051-.643a4.758 4.758 0 0 1-4.713 4.12Z'
          fill='#000'
          fillOpacity='.2'
          opacity='.2'
        />
        <path
          d='m87.423 62.336-25.865-12.23a3.695 3.695 0 0 0-3.129 0l-25.864 12.23a3.65 3.65 0 1 0 3.122 6.592l24.307-11.503L84.3 68.928a3.65 3.65 0 1 0 3.122-6.592Z'
          fill='#E30010'
        />
        <path
          d='m96.847 47.801-6.875-3.257v-6.09a5.478 5.478 0 0 0-10.944 0v.908l-16.691-7.899a4.837 4.837 0 0 0-.502-.206l-.187-.057c-.11 0-.213-.071-.328-.097a1.84 1.84 0 0 0-.245-.052L60.798 31h-1.216c-.136 0-.264 0-.4.039h-.09l-.43.083h-.052a5.57 5.57 0 0 0-.734.251l-.206.084-34.53 16.344a5.472 5.472 0 1 0 4.668 9.9l32.186-15.23 32.186 15.23a5.4 5.4 0 0 0 2.336.528 5.472 5.472 0 0 0 2.343-10.421l-.012-.007Z'
          fill='#E30010'
        />
        <path
          d='M99.454 53.8a5.477 5.477 0 0 1-4.95 3.136 5.4 5.4 0 0 1-2.324-.522l-32.186-15.23-32.186 15.23a5.4 5.4 0 0 1-2.337.528 5.479 5.479 0 0 1-4.95-3.135 5.359 5.359 0 0 1-.438-1.693 5.42 5.42 0 0 0 .483 2.974 5.478 5.478 0 0 0 4.95 3.135 5.402 5.402 0 0 0 2.292-.521l32.186-15.23 32.186 15.23c.73.348 1.528.529 2.336.527a5.478 5.478 0 0 0 4.95-3.135 5.419 5.419 0 0 0 .483-2.974 5.355 5.355 0 0 1-.495 1.68Z'
          fill='#000'
          fillOpacity='.2'
          opacity='.2'
        />
        <path
          d='m89.972 45.831 6.875 3.258a5.465 5.465 0 0 1 3.09 4.313 5.458 5.458 0 0 0-3.09-5.6l-6.875-3.258v1.287ZM23.14 49.089 57.65 32.75l.206-.084c.239-.1.484-.184.734-.25h.052l.431-.084h.09c.135 0 .264-.033.4-.04h1.216l.277.052c.082.012.164.03.244.052.116 0 .22.064.329.096l.186.058c.171.06.339.128.502.206l16.686 7.899v-.914a5.478 5.478 0 0 1 10.943 0v-1.288a5.478 5.478 0 0 0-10.943 0v.908l-16.666-7.899a4.837 4.837 0 0 0-.502-.206l-.187-.057c-.11 0-.213-.071-.328-.097a1.84 1.84 0 0 0-.245-.052l-.277-.05h-1.216c-.136 0-.264 0-.4.039h-.09l-.43.083h-.052a5.57 5.57 0 0 0-.734.251l-.206.084-34.53 16.344a5.46 5.46 0 0 0-3.09 5.6 5.466 5.466 0 0 1 3.09-4.312Z'
          fill='#fff'
          opacity='.2'
        />
        <path
          d='m77.458 72.217-16.093-7.615a3.217 3.217 0 0 0-2.742 0L42.53 72.217a3.219 3.219 0 0 0 2.729 5.794l14.735-6.99 14.734 6.97a3.218 3.218 0 0 0 2.73-5.793v.02Z'
          fill='#E30010'
        />
        <path
          d='M78.977 75.185a3.187 3.187 0 0 1-4.249 1.52l-14.734-6.972-14.735 6.971a3.218 3.218 0 0 1-4.249-1.519 3.304 3.304 0 0 1-.231-.715 3.148 3.148 0 0 0 .231 2.002 3.219 3.219 0 0 0 4.249 1.52l14.735-6.972 14.734 6.972a3.187 3.187 0 0 0 4.249-1.52 3.148 3.148 0 0 0 .232-2.001c-.05.246-.128.486-.232.714Z'
          fill='#000'
          fillOpacity='.2'
          opacity='.2'
        />
        <path
          d='m32.565 63.624 25.864-12.23a3.694 3.694 0 0 1 3.129 0l25.865 12.23a3.644 3.644 0 0 1 2.027 2.652 3.643 3.643 0 0 0-2.027-3.94l-25.865-12.23a3.695 3.695 0 0 0-3.129 0l-25.864 12.23a3.643 3.643 0 0 0-2.028 3.94 3.644 3.644 0 0 1 2.028-2.652Z'
          fill='#fff'
          opacity='.2'
        />
        <path
          d='M89.16 65.909a3.643 3.643 0 0 1-4.86 1.732L59.994 56.137 35.686 67.641a3.65 3.65 0 0 1-4.86-1.732 3.81 3.81 0 0 1-.29-.92 3.643 3.643 0 0 0 5.15 3.939l24.306-11.503L84.3 68.928a3.643 3.643 0 0 0 5.15-3.94 3.81 3.81 0 0 1-.29.92Z'
          fill='#000'
          fillOpacity='.2'
          opacity='.2'
        />
        <path
          d='m42.53 73.505 16.092-7.615a3.22 3.22 0 0 1 2.743 0l16.093 7.615a3.172 3.172 0 0 1 1.75 2.253 3.18 3.18 0 0 0-1.75-3.54l-16.093-7.616a3.218 3.218 0 0 0-2.743 0L42.53 72.217a3.18 3.18 0 0 0-1.75 3.54 3.172 3.172 0 0 1 1.75-2.252Zm17.463 7.873a4.75 4.75 0 0 1 4.713 4.113 4.27 4.27 0 0 0 .051-.644 4.764 4.764 0 0 0-9.527 0c0 .216.018.431.052.644a4.751 4.751 0 0 1 4.712-4.113Z'
          fill='#fff'
          opacity='.2'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconSuperConnectAndroid
