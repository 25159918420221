import type { FC } from 'react'
import React from 'react'

import type { IconTagntrackIosProps } from './IconTagntrackIos.types'
import * as Styled from './styles/IconTagntrackIos.style'

import { nonThemeableRules } from '../../ruleSet'

const IconTagntrackIos: FC<IconTagntrackIosProps> = ({
  size = nonThemeableRules.defaultSize as number as IconTagntrackIosProps['size'],
  isResponsive = false,
}: IconTagntrackIosProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M87.778 10H32.222C19.95 10 10 19.95 10 32.222v55.556C10 100.05 19.95 110 32.222 110h55.556C100.05 110 110 100.051 110 87.778V32.222C110 19.95 100.051 10 87.778 10Z'
          fill='#E60000'
        />
        <path
          d='M60 22c-9.331.014-16.891 7.574-16.905 16.905a16.814 16.814 0 0 0 2.1 8.183c4.599 10.059 14.252 27.839 14.35 28a.476.476 0 0 0 .84 0c.098-.175 9.751-17.955 14.35-28a16.814 16.814 0 0 0 2.1-8.183C76.821 29.602 69.303 22.056 60 22Z'
          fill='#fff'
        />
        <path
          d='M68.225 89.417c0-.973-.042-6.167-.042-6.23v-6.741L60 79.379l-1.4-.553-14.798-5.88-5.502 2.541a.505.505 0 0 1-.469 0 .475.475 0 0 1-.231-.441v-6.594L25 74.276v16.52c0 .343.07.679.203.994a2.603 2.603 0 0 0 1.358 1.365c.28.133.574.21.882.231a.65.65 0 0 0 .203-.035c3.325-1.519 15.064-7 16.142-7.469l16.1 7.469h.161l8.155-3.773.021-.161Z'
          fill='#fff'
        />
        <path
          d='m43.998 64.588 8.932 4.2v-3.003c-3.045-5.782-6.706-12.922-9.03-18.039-.091-.168-.175-.343-.266-.518l-12.572 5.593a.51.51 0 0 1 .049.203v8.771l4.116-1.701a.5.5 0 0 1 .644.273.494.494 0 0 1-.259.644l-4.802 1.974a.467.467 0 0 1-.462-.042.49.49 0 0 1-.224-.413v-9.289l-2.408 1.085-.553.238-.581.259a2.42 2.42 0 0 0-.819.553c-.238.238-.42.511-.553.819a2.55 2.55 0 0 0-.203.994v15.995l18.578-8.603a.517.517 0 0 1 .42.007h-.007Z'
          fill='#fff'
        />
        <path
          d='m69.562 62.943 6.643-2.415.483.231v-2.856a.51.51 0 0 1 .217-.413.505.505 0 0 1 .469 0L95 65.19v-8.001c0-.343-.07-.679-.203-.994a2.603 2.603 0 0 0-.553-.819 2.42 2.42 0 0 0-.819-.553l-.581-.259-.553-.238-15.89-7.147c-.091.175-.168.35-.266.518-4.627 10.115-14.301 27.93-14.392 28.105a1.96 1.96 0 0 1-3.451 0c-.042-.077-1.897-3.5-4.361-8.162v1.911c0 .273-.238.49-.511.483a.438.438 0 0 1-.189-.042l-9.429-4.452-5.208 2.415v6.3l5.005-2.303a.541.541 0 0 1 .392 0l15.981 6.342h.049c.574-.21 8.596-3.199 8.827-3.15a.446.446 0 0 1 .308.455v13.517l7.042-3.255c.77.357 7.035 3.269 11.739 5.439V76.341l-9.219-3.465a.49.49 0 0 1-.322-.462v-3.955c0-.273.224-.497.497-.497s.497.224.497.497v3.612l9.219 3.5a.49.49 0 0 1 .322.462v15.743l3.409 1.568a.403.403 0 0 0 .203.035c.308-.021.602-.098.882-.231a2.603 2.603 0 0 0 1.358-1.365 2.55 2.55 0 0 0 .203-.994V66.226l-17.311-7.567v2.884a.51.51 0 0 1-.231.42.52.52 0 0 1-.476 0l-.763-.364-6.3 2.282a.37.37 0 0 1-.175 0 .51.51 0 0 1-.518-.504c0-.224.133-.42.343-.49v.056h.007Z'
          fill='#fff'
        />
        <path
          d='M60 49.601c6.6 0 11.949-5.35 11.949-11.949 0-6.6-5.35-11.949-11.949-11.949-6.6 0-11.949 5.35-11.949 11.949 0 6.6 5.35 11.949 11.949 11.949Z'
          fill='#E60000'
        />
        <path
          d='M51.86 37.449a1.123 1.123 0 0 1-1.114-1.127c0-.287.12-.567.322-.777a12.758 12.758 0 0 1 9.1-3.766 12.751 12.751 0 0 1 9.1 3.759 1.12 1.12 0 0 1-1.61 1.554 10.496 10.496 0 0 0-7.497-3.101 10.514 10.514 0 0 0-7.51 3.129c-.21.21-.498.329-.792.329Z'
          fill='#fff'
        />
        <path
          d='M66.125 40.046c-.287 0-.567-.112-.777-.308a7.263 7.263 0 0 0-5.13-2.1 7.192 7.192 0 0 0-5.132 2.1 1.11 1.11 0 0 1-1.575 0 1.11 1.11 0 0 1 0-1.575 9.493 9.493 0 0 1 13.42 0c.447.413.475 1.106.062 1.554l-.014.014a1.116 1.116 0 0 1-.847.315h-.007Z'
          fill='#fff'
        />
        <path d='M60.21 43.119a1.792 1.792 0 1 0 0-3.584 1.792 1.792 0 0 0 0 3.584Z' fill='#fff' />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconTagntrackIos
