import type { FC } from 'react'
import React from 'react'

import type { IconVodafoneGhostProps } from './IconVodafoneGhost.types'
import * as Styled from './styles/IconVodafoneGhost.style'

import { nonThemeableRules } from '../../ruleSet'

const IconVodafoneGhost: FC<IconVodafoneGhostProps> = ({
  size = nonThemeableRules.defaultSize as number as IconVodafoneGhostProps['size'],
  isResponsive = false,
}: IconVodafoneGhostProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M110 60c0 27.614-22.386 50-50 50S10 87.614 10 60s22.386-50 50-50a49.806 49.806 0 0 1 21.327 4.764c-.863-.27-2.212-.368-3.504-.368-10.407.035-21.88 4.484-30.255 11.42-8.587 7.125-15.304 19.11-15.272 31.56.06 18.827 14.355 30.554 28.092 30.508 16.973-.056 27.006-13.998 26.963-26.71-.042-12.71-6.953-21.896-22.18-25.604a5.218 5.218 0 0 1-.06-.815c-.03-9.6 7.201-18.06 16.29-19.957C98.308 22.819 110 40.044 110 60Z'
          fill='#fff'
          fillRule='evenodd'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconVodafoneGhost
