import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { getIconSize } from '@vfuk/core-icon'

import type { StyledIconGooglePlayWhiteProps } from './IconGooglePlayWhite.style.types'

import ruleSet from '../../../ruleSet'

export const IconWrapper = styled.span<StyledIconGooglePlayWhiteProps>(
  (props: StyledIconGooglePlayWhiteProps): FlattenSimpleInterpolation => {
    const localTheme = ruleSet()

    return css`
      position: relative;
      display: inline-flex;
      vertical-align: middle;

      span {
        display: inline-flex;
      }

      svg {
        ${getIconSize(props.isResponsive, localTheme.size[props.size!], localTheme.widthOverride)}
      }
    `
  },
)
