import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing } from '@vfuk/core-mixins'

import { StyledDividerProps } from './Divider.style.types'

import localThemes from '../themes/Divider.theme'

export const Divider = styled.hr<StyledDividerProps>((props: StyledDividerProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  const isHorizontal = props.orientation === 'horizontal'
  const borderColor = props.inverse
    ? localTheme.appearance[props.appearance!].inverse.color
    : localTheme.appearance[props.appearance!].color

  const variableMargin = props.noMargin ? 0 : 2
  const dividerStyle = `${props.theme.border.width[1]} ${localTheme.level[props.level!].borderStyle} ${borderColor}`

  return css`
    width: ${isHorizontal ? '100%' : 0};
    height: ${isHorizontal ? 0 : '100%'};

    ${isHorizontal &&
    css`
      ${advancedSpacing('margin', [variableMargin, 0])}
    `}

    ${!isHorizontal &&
    css`
      ${advancedSpacing('margin', [0, variableMargin])}
    `}

    padding: 0;
    border: 0;
    ${isHorizontal ? 'border-top' : 'border-left'}: ${dividerStyle};
  `
})
