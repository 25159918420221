import type { FC } from 'react'
import React from 'react'

import type { IconVsosbandAndroidProps } from './IconVsosbandAndroid.types'
import * as Styled from './styles/IconVsosbandAndroid.style'

import { nonThemeableRules } from '../../ruleSet'

const IconVsosbandAndroid: FC<IconVsosbandAndroidProps> = ({
  size = nonThemeableRules.defaultSize as number as IconVsosbandAndroidProps['size'],
  isResponsive = false,
}: IconVsosbandAndroidProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <rect height='100' width='100' fill='#E60000' rx='30' x='10' y='10' />
        <path d='m40.448 50.143-4.98 12.747-5.235-12.747H25l8.59 21.156 8.795-21.156h-1.937Z' fill='#fff' />
        <path
          d='m87.496 66.713-1.512-7.802c-.252-1.227-1.322-2.11-2.54-2.11h-3.552c-2.2-6.561-7.46-11.015-15.677-13.244.094-6.465 4.805-12.423 11.101-13.959.692-.142 1.006-.69 1.01-1.167.004-.555-.366-1.02-.983-1.187 0 0-.02-.008-.031-.01-.853-.197-1.898-.246-2.606-.232-7.965.027-17.012 3.498-23.61 9.062-2.566 2.164-4.92 4.906-6.81 7.933a1.118 1.118 0 0 0 1.898 1.184c1.768-2.831 3.965-5.394 6.356-7.41 5.512-4.648 12.822-7.745 19.611-8.404-4.894 3.02-8.191 8.567-8.173 14.438 0 .234.019.595.068.84.083.426.407.765.828.87 7.565 1.875 12.48 5.67 14.648 11.281h-5.88c-1.222 0-2.263.843-2.537 2.061l-1.733 7.82a1.181 1.181 0 0 0-.027.242v15.073c-2.401 1.057-5.026 1.604-7.818 1.614h-.058c-6.503 0-12.85-3.704-16.578-9.68a1.118 1.118 0 0 0-1.895 1.18C45.126 81.732 52.2 85.84 59.469 85.84h.066c2.76-.01 5.376-.496 7.81-1.433v2.484c0 .08.008.16.027.24l1.726 7.834C69.381 96.166 70.42 97 71.622 97h11.605c1.202 0 2.24-.826 2.534-2.036l1.732-7.82a1.15 1.15 0 0 0 .027-.242V66.92c0-.07-.006-.142-.02-.213l-.004.007ZM71.282 59.35c.041-.186.19-.314.366-.314h11.787c.171 0 .322.136.357.312l1.204 6.215h-15.09l1.377-6.213ZM83.58 94.466c-.045.182-.192.308-.36.308H71.633a.395.395 0 0 1-.357-.297l-1.373-6.233h15.056l-1.378 6.22v.002Zm1.7-8.803c0 .192-.137.35-.304.35H69.88c-.167 0-.304-.158-.304-.35V68.148c0-.192.137-.35.304-.35h15.097c.167 0 .304.158.304.35v17.515Z'
          fill='#fff'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconVsosbandAndroid
