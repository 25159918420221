import type { FC } from 'react'
import React from 'react'

import type { IconZiggoProps } from './IconZiggo.types'
import * as Styled from './styles/IconZiggo.style'

import { nonThemeableRules } from '../../ruleSet'

const IconZiggo: FC<IconZiggoProps> = ({
  size = nonThemeableRules.defaultSize as number as IconZiggoProps['size'],
  isResponsive = false,
}: IconZiggoProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M87.58 86.715H42.638c-4.686 0-7.23-1.372-8.743-4.772-1.658-3.771.457-7.485 1.714-8.685l31.657-29.115h-21.4c-6.571-1.028-9.057-6.857-9.228-11.714h37.428c4.772 0 7.486 1.486 9.114 4.971 2 4.315-.828 8.343-2.085 9.515l-30.029 28.2h26.057c7.343.857 10.257 7.371 10.457 11.6Zm-65.228-.429c0 2.857 1.171 5.429 3.085 7.286 1.943 1.857 4.572 3 7.486 3h55.029c2.914 0 5.543-1.143 7.486-3a10.113 10.113 0 0 0 3.085-7.286V43.058c0-5.458 5.686-10.543 11.914-10.572v53.8c0 6.029-2.514 11.514-6.571 15.429a22.74 22.74 0 0 1-15.943 6.428h-55a22.742 22.742 0 0 1-15.886-6.4 21.453 21.453 0 0 1-6.6-15.428V32.829c0-6.029 2.515-11.514 6.572-15.457 4.28-4.123 10-6.41 15.943-6.371h55a22.97 22.97 0 0 1 16.828 7.285 5.675 5.675 0 0 1 1.732 4.086 5.677 5.677 0 0 1-1.732 4.086 6.057 6.057 0 0 1-8.428 0 12 12 0 0 0-4.286-3.086 10.829 10.829 0 0 0-4.114-.8H32.923a10.783 10.783 0 0 0-7.486 3 10.114 10.114 0 0 0-3.085 7.286v53.428Z'
          fill='#F48C00'
          fillRule='evenodd'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconZiggo
