import type { FC } from 'react'
import React from 'react'

import type { IconVappAndroidProps } from './IconVappAndroid.types'
import * as Styled from './styles/IconVappAndroid.style'

import { nonThemeableRules } from '../../ruleSet'

const IconVappAndroid: FC<IconVappAndroidProps> = ({
  size = nonThemeableRules.defaultSize as number as IconVappAndroidProps['size'],
  isResponsive = false,
}: IconVappAndroidProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <rect height='100' width='100' fill='#E60000' rx='30' x='10' y='10' />
        <path d='m51.446 48.839-6.916 17.68-7.266-17.68H30L41.925 78.18l12.21-29.342h-2.69Z' fill='#fff' fillRule='evenodd' />
        <path
          d='M63.036 96c-6.77 0-13.324-2.63-18.45-7.408a1.2 1.2 0 0 1 1.638-1.754c4.68 4.363 10.652 6.763 16.812 6.763 13.331 0 24.245-10.695 24.329-23.843.038-5.974-1.843-11.315-5.439-15.442-3.55-4.075-8.586-6.778-14.566-7.82a1.198 1.198 0 0 1-.99-1.096c-.014-.168-.272-4.148 1.606-8.732 1.353-3.304 4.054-7.415 9.438-10.228-7.353.394-14.4 3.565-14.49 3.605-.025.01-.052.024-.08.033-.08.029-8.112 3.089-15.058 10.477a1.2 1.2 0 1 1-1.75-1.643c7.164-7.617 15.206-10.79 15.929-11.066.858-.386 11.852-5.218 21.254-3.464a1.199 1.199 0 0 1 .095 2.337c-6.437 1.75-10.838 5.373-13.08 10.768a19.77 19.77 0 0 0-1.473 6.835c12.877 2.652 21.087 12.542 21.005 25.45-.093 14.461-12.084 26.226-26.73 26.226'
          fill='#fff'
          fillRule='evenodd'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconVappAndroid
