import type { FC } from 'react'
import React from 'react'

import type { IconYornProps } from './IconYorn.types'
import * as Styled from './styles/IconYorn.style'

import { nonThemeableRules } from '../../ruleSet'

const IconYorn: FC<IconYornProps> = ({
  size = nonThemeableRules.defaultSize as number as IconYornProps['size'],
  isResponsive = false,
}: IconYornProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M28.6 17.819a1.625 1.625 0 0 0-1.3-.763H16.587c-.856 0-1.206.625-.78 1.357l37.318 65.08a1.656 1.656 0 0 1 0 1.507l-3.462 6.013a1.025 1.025 0 0 1-.782.462h-4.618c-.863 0-1.213-.625-.782-1.362l2.706-4.913a1.693 1.693 0 0 0 0-1.531L40.8 74.294a.832.832 0 0 0-1.531 0l-15.625 27.275c-.425.75-.088 1.362.781 1.362H55.45a1.015 1.015 0 0 0 .8-.431l9.375-16.25a4.318 4.318 0 0 0 0-3.931L28.6 17.819Zm74.219-.763H72.725a.98.98 0 0 0-.775.456l-7.931 13.75a1.032 1.032 0 0 0 0 .9l5 8.694a1.533 1.533 0 0 0 2.075.625c.234-.128.431-.313.575-.537l6.875-11.982a.962.962 0 0 1 .781-.462h4.594c.875 0 1.25.625.781 1.363l-13.75 23.98a.82.82 0 0 1-1.349.275.838.838 0 0 1-.182-.274L48.788 17.806a1.649 1.649 0 0 0-1.288-.768H36.775c-.856 0-1.206.625-.781 1.362l32.131 56.081c1.144 1.988 3.063 1.988 4.175 0l31.806-55.106c.656-1.294.075-2.319-1.287-2.319Z'
          fill='#fff'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconYorn
