import type { FC } from 'react'
import React from 'react'

import type { IconVodafoneIdeaProps } from './IconVodafoneIdea.types'
import * as Styled from './styles/IconVodafoneIdea.style'

import { nonThemeableRules } from '../../ruleSet'

const IconVodafoneIdea: FC<IconVodafoneIdeaProps> = ({
  size = nonThemeableRules.defaultSize as number as IconVodafoneIdeaProps['size'],
  isResponsive = false,
}: IconVodafoneIdeaProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#6bcffe40-bd42-4728-b7c8-f80729c2a6b2__a)'>
          <path
            d='M76.63 72.18h16.742V25.128h-16.74v47.056l-.002-.004ZM54.42 25.128 43.58 68.976l-10.838-43.85H16l11.632 47.056h31.9L71.16 25.126H54.42v.002Z'
            fill='#ED303B'
            fillRule='evenodd'
          />
          <path d='M94.116 86.764a9.113 9.113 0 0 1-9.114 9.114 9.115 9.115 0 1 1 9.114-9.114Z' fill='#FFD300' fillRule='evenodd' />
          <mask
            height='72'
            id='6bcffe40-bd42-4728-b7c8-f80729c2a6b2__b'
            style={{ maskType: 'alpha' }}
            width='89'
            x='16'
            y='24'
            maskUnits='userSpaceOnUse'
          >
            <path d='M16 95.878h88.274v-71.64H16v71.64Z' fill='#fff' />
          </mask>
          <g mask='url(#6bcffe40-bd42-4728-b7c8-f80729c2a6b2__b)'>
            <path
              d='M96.712 23.023v2.129h-.278v-2.13h.278Zm.684 0v.23h-1.645v-.23h1.645Zm.406 0h.273l.697 1.736.697-1.736h.275l-.866 2.129h-.214l-.862-2.13Zm-.09 0h.242l.04 1.298v.831h-.282v-2.13Zm1.878 0h.241v2.129h-.28v-.83l.039-1.3Z'
              fill='#ED303B'
            />
          </g>
        </g>
        <defs>
          <clipPath id='6bcffe40-bd42-4728-b7c8-f80729c2a6b2__a'>
            <path d='M0 0h120v120H0z' fill='#fff' />
          </clipPath>
        </defs>
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconVodafoneIdea
