import type { FC } from 'react'
import React from 'react'

import type { IconConnectedLivingIosProps } from './IconConnectedLivingIos.types'
import * as Styled from './styles/IconConnectedLivingIos.style'

import { nonThemeableRules } from '../../ruleSet'

const IconConnectedLivingIos: FC<IconConnectedLivingIosProps> = ({
  size = nonThemeableRules.defaultSize as number as IconConnectedLivingIosProps['size'],
  isResponsive = false,
}: IconConnectedLivingIosProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M87.778 10H32.222C19.95 10 10 19.95 10 32.222v55.556C10 100.05 19.95 110 32.222 110h55.556C100.05 110 110 100.051 110 87.778V32.222C110 19.95 100.051 10 87.778 10Z'
          fill='#E60000'
        />
        <mask
          height='68'
          id='752c847d-85da-4428-ad41-add68b82638f__a'
          style={{ maskType: 'alpha' }}
          width='68'
          x='26'
          y='23'
          maskUnits='userSpaceOnUse'
        >
          <path d='M26 23h68v68H26V23Z' fill='#fff' />
        </mask>
        <g mask='url(#752c847d-85da-4428-ad41-add68b82638f__a)'>
          <path
            d='M90.683 45.237a5.173 5.173 0 0 0-.463-.403L64.246 24.692l-.231-.166a6.023 6.023 0 0 0-3.908-1.421h-.146a6.024 6.024 0 0 0-3.881 1.421l-.219.166-25.987 20.142a5.22 5.22 0 0 0-.463.403c-2.229 2.235-3.306 3.882-3.306 7.195V82.3a8.544 8.544 0 0 0 8.53 8.53h21.603a39.193 39.193 0 0 1-9.04-5.984H34.636A2.553 2.553 0 0 1 32.09 82.3V52.028a2.643 2.643 0 0 1 0-.33c.01-.094.028-.187.053-.278.027-.088.06-.174.099-.258v-.04c.046-.079.093-.165.152-.258l.199-.25.072-.093c.086-.1.185-.212.298-.324.042-.052.089-.1.139-.146a7.53 7.53 0 0 1 .337-.35l.066-.066 25.922-20.096.046-.04.119-.092a2.45 2.45 0 0 1 .238-.172H60c.083.052.162.11.238.172l.119.092.046.04 26.093 19.977.067.066c.125.125.238.238.337.35.05.045.096.094.139.145.112.113.211.225.297.325l.073.092.199.251c.059.093.105.179.152.258v.04c.038.084.072.17.099.258.025.09.043.184.053.278.007.11.007.22 0 .33v30.272a2.553 2.553 0 0 1-2.546 2.547h-15.87c-4.788 0-9.47-2.328-15.21-7.545-6.42-5.86-9.919-11.473-9.919-15.818v-.317a8.24 8.24 0 0 1 8.3-8.114c1.904 0 4.106 2.03 5.95 2.936a2.97 2.97 0 0 0 2.646 0c1.732-.88 4.16-2.916 5.951-2.916a8.24 8.24 0 0 1 8.233 8.233v.317c0 5.131-5.026 11.056-7.73 14.237a2.995 2.995 0 0 0 4.562 3.875c3.2-3.763 9.159-10.778 9.159-18.112v-.317A14.237 14.237 0 0 0 67.215 46.97c-3.723 0-5.045 1.983-7.353 2.955-2.374-.945-3.75-2.955-7.274-2.955a14.237 14.237 0 0 0-14.224 14.217v.317c0 7.75 6.474 15.282 11.903 20.241 6.904 6.296 12.835 9.106 19.223 9.106h15.87a8.543 8.543 0 0 0 8.53-8.53V52.43c.086-3.312-.978-4.959-3.207-7.194Z'
            fill='#fff'
          />
        </g>
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconConnectedLivingIos
