import type { FC } from 'react'
import React from 'react'

import type { IconVodafoneYuProps } from './IconVodafoneYu.types'
import * as Styled from './styles/IconVodafoneYu.style'

import { nonThemeableRules } from '../../ruleSet'

const IconVodafoneYu: FC<IconVodafoneYuProps> = ({
  size = nonThemeableRules.defaultSize as number as IconVodafoneYuProps['size'],
  isResponsive = false,
}: IconVodafoneYuProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path d='m20 10 1.951 99.512 20.912-15.525L99.61 86.47l.39-68.665L20 10Z' fill='#E60000' />
        <path
          d='M59.9 37.386c-1.32 5.174-2.752 10.02-4.293 14.313a93.715 93.715 0 0 1-4.515 11.01c-.66 1.322-1.431 2.643-2.092 3.965a14.38 14.38 0 0 1-2.862 3.523 12.583 12.583 0 0 1-4.515 2.422 20.136 20.136 0 0 1-6.936.991l-.551-5.945c.736.015 1.472-.022 2.202-.11a18.72 18.72 0 0 0 2.422-.44c.789-.246 1.561-.54 2.313-.882a4.008 4.008 0 0 0 1.761-1.541c.11-.22.22-.33.33-.55l.331-.661-10.68-25.985h7.157l7.157 19.929a63.527 63.527 0 0 0 1.651-4.404c.55-1.652 1.101-3.304 1.541-5.065.441-1.762.992-3.524 1.432-5.285.44-1.762.88-3.524 1.321-5.175h6.827v-.11ZM73.225 63.92c-3.084 0-5.396-.88-6.937-2.753-1.542-1.871-2.312-4.624-2.312-8.258V37.496h6.716V52.25a8.985 8.985 0 0 0 .991 4.404 3.546 3.546 0 0 0 3.303 1.652 6.122 6.122 0 0 0 3.303-.771c.758-.44 1.493-.918 2.203-1.431V37.385h6.716V63.37H81.04l-.22-2.091a12.344 12.344 0 0 1-3.634 2.091 18.91 18.91 0 0 1-3.963.551Z'
          fill='#fff'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconVodafoneYu
