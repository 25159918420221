import type { FC } from 'react'
import React from 'react'

import type { IconMvaIosProps } from './IconMvaIos.types'
import * as Styled from './styles/IconMvaIos.style'

import { nonThemeableRules } from '../../ruleSet'

const IconMvaIos: FC<IconMvaIosProps> = ({
  size = nonThemeableRules.defaultSize as number as IconMvaIosProps['size'],
  isResponsive = false,
}: IconMvaIosProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M87.778 10H32.222C19.95 10 10 19.95 10 32.222v55.556C10 100.05 19.95 110 32.222 110h55.556C100.05 110 110 100.051 110 87.778V32.222C110 19.95 100.051 10 87.778 10Z'
          fill='#E60000'
        />
        <mask
          height='74'
          id='01634f68-9237-4b45-ac68-1692aeea2873__a'
          style={{ maskType: 'alpha' }}
          width='74'
          x='23'
          y='23'
          maskUnits='userSpaceOnUse'
        >
          <path d='M23.889 23.889H96.11V96.11H23.89V23.89Z' fill='#fff' />
        </mask>
        <g mask='url(#01634f68-9237-4b45-ac68-1692aeea2873__a)'>
          <path
            d='M77.66 69.699a18.896 18.896 0 0 1-5.165 6.274A19.501 19.501 0 0 1 60.2 80.135a19.313 19.313 0 0 1-5.137-.77 20.454 20.454 0 0 1-8.886-5.327 20.435 20.435 0 0 1-2.636-3.3 21.981 21.981 0 0 1-2.752-6.288 24.17 24.17 0 0 1-.66-3.568 25.648 25.648 0 0 1-.106-4.104c.101-1.788.369-3.521.779-5.191 1.643-6.667 5.566-12.302 10.163-16.191 3.064-2.63 6.534-4.505 9.894-5.798a40.34 40.34 0 0 1 2.96-1.005 45.054 45.054 0 0 1 1.843-.499c1.654-.4 3.2-.664 4.574-.818 1.946-.213 3.535-.204 4.557-.04.292.046.533.103.731.174-6.714 1.45-11.774 7.387-11.774 14.48 0 .154.035.34.042.486 2.95.623 5.496 1.622 7.632 2.942 2.047 1.262 3.716 2.818 5.01 4.619.101.139.197.28.293.42a17.113 17.113 0 0 1 2.179 4.628c.493 1.636.773 3.387.84 5.23.007.237.014.472.014.707 0 3.075-.728 6.083-2.1 8.777ZM60 23.889c-19.944 0-36.111 16.167-36.111 36.112 0 19.943 16.167 36.11 36.111 36.11 19.942 0 36.111-16.167 36.111-36.11 0-19.945-16.169-36.112-36.111-36.112Z'
            fill='#fff'
          />
        </g>
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconMvaIos
