import type { FC } from 'react'
import React from 'react'

import type { IconSuperWifiIosProps } from './IconSuperWifiIos.types'
import * as Styled from './styles/IconSuperWifiIos.style'

import { nonThemeableRules } from '../../ruleSet'

const IconSuperWifiIos: FC<IconSuperWifiIosProps> = ({
  size = nonThemeableRules.defaultSize as number as IconSuperWifiIosProps['size'],
  isResponsive = false,
}: IconSuperWifiIosProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M87.778 10H32.222C19.95 10 10 19.95 10 32.222v55.556C10 100.05 19.95 110 32.222 110h55.556C100.05 110 110 100.051 110 87.778V32.222C110 19.95 100.051 10 87.778 10Z'
          fill='url(#a5c1485b-9f1e-4975-ba1d-d3dbb65d2c5e__a)'
        />
        <path
          d='M97.77 47.43 82.991 32.28a3.865 3.865 0 0 0-2.767-1.17h-40.45c-1.04 0-2.036.422-2.766 1.17L22.23 47.43a3.962 3.962 0 0 0 0 5.52l35.003 35.88a3.856 3.856 0 0 0 5.534 0l35.002-35.88a3.962 3.962 0 0 0 0-5.52ZM60 77.211c-2.62 0-4.745-2.148-4.745-4.798s2.124-4.798 4.744-4.798c2.62 0 4.745 2.148 4.745 4.798s-2.124 4.798-4.745 4.798Zm11.958-10.297-.045.045c-.716.725-1.905.565-2.428-.312-1.94-3.25-5.465-5.429-9.485-5.429s-7.545 2.178-9.484 5.428c-.523.878-1.713 1.038-2.429.313l-.045-.045a1.592 1.592 0 0 1-.222-1.937C50.316 60.81 54.841 58.02 60 58.02c5.16 0 9.684 2.79 12.18 6.957a1.592 1.592 0 0 1-.222 1.937Zm6.82-6.897-.046.046a1.536 1.536 0 0 1-2.329-.162c-3.756-5.025-9.709-8.279-16.403-8.279-6.694 0-12.647 3.255-16.403 8.279a1.536 1.536 0 0 1-2.329.162l-.045-.046a1.58 1.58 0 0 1-.148-2.05C45.41 52.175 52.28 48.425 60 48.425s14.59 3.75 18.925 9.543a1.58 1.58 0 0 1-.147 2.05Zm6.75-6.825-.043.042c-.653.66-1.718.592-2.306-.128-5.515-6.76-13.856-11.08-23.179-11.08-9.323 0-17.663 4.32-23.178 11.08a1.547 1.547 0 0 1-2.307.128l-.042-.043a1.584 1.584 0 0 1-.103-2.108C40.466 43.606 49.691 38.829 60 38.829c10.31 0 19.534 4.777 25.63 12.255a1.584 1.584 0 0 1-.102 2.108Z'
          fill='#fff'
        />
        <defs>
          <linearGradient id='a5c1485b-9f1e-4975-ba1d-d3dbb65d2c5e__a' gradientUnits='userSpaceOnUse' x1='60' x2='60' y1='12.041' y2='110'>
            <stop stopColor='#E60000' />
            <stop offset='.516' stopColor='#E40000' />
            <stop offset='.796' stopColor='#D00000' />
            <stop offset='.929' stopColor='#BE0000' />
            <stop offset='1' stopColor='#B20000' />
          </linearGradient>
        </defs>
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconSuperWifiIos
