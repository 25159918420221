import type { FC } from 'react'
import React from 'react'

import type { IconMvaAndroidProps } from './IconMvaAndroid.types'
import * as Styled from './styles/IconMvaAndroid.style'

import { nonThemeableRules } from '../../ruleSet'

const IconMvaAndroid: FC<IconMvaAndroidProps> = ({
  size = nonThemeableRules.defaultSize as number as IconMvaAndroidProps['size'],
  isResponsive = false,
}: IconMvaAndroidProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M60 104.308c24.47 0 44.308-19.838 44.308-44.308S84.47 15.692 60 15.692 15.692 35.53 15.692 60 35.53 104.308 60 104.308Z'
          fill='#fff'
        />
        <mask
          height='90'
          id='28965422-0a45-4547-8474-69b12248f235__a'
          style={{ maskType: 'alpha' }}
          width='90'
          x='15'
          y='15'
          maskUnits='userSpaceOnUse'
        >
          <path d='M15 15h90v90H15V15Z' fill='#fff' />
        </mask>
        <g mask='url(#28965422-0a45-4547-8474-69b12248f235__a)'>
          <path
            d='M82.007 72.086a23.548 23.548 0 0 1-6.437 7.82 24.301 24.301 0 0 1-15.32 5.185 24.01 24.01 0 0 1-2.55-.173 24.072 24.072 0 0 1-3.852-.787c-4.14-1.197-7.971-3.479-11.074-6.638a25.474 25.474 0 0 1-3.285-4.11 27.396 27.396 0 0 1-3.429-7.838 30.115 30.115 0 0 1-.824-4.446 31.967 31.967 0 0 1-.152-4.644 35.53 35.53 0 0 1 .992-6.94c2.047-8.306 6.937-15.329 12.665-20.175 3.818-3.279 8.143-5.614 12.33-7.225a50.278 50.278 0 0 1 3.688-1.254c.778-.23 1.548-.434 2.297-.62 2.061-.5 3.987-.829 5.7-1.02 2.425-.265 4.405-.254 5.678-.05.365.057.664.129.912.217-8.368 1.809-14.673 9.205-14.673 18.045 0 .191.043.423.052.605 3.677.777 6.849 2.02 9.51 3.666 2.552 1.572 4.631 3.512 6.245 5.755.126.174.245.35.364.525a21.32 21.32 0 0 1 2.716 5.767c.614 2.038.963 4.22 1.046 6.518.009.295.018.587.018.88a24.1 24.1 0 0 1-2.617 10.937ZM60 15c-24.853 0-45 20.147-45 45.001C15 84.853 35.147 105 60 105c24.851 0 45-20.147 45-44.999C105 35.147 84.851 15 60 15Z'
            fill='#E60000'
          />
        </g>
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconMvaAndroid
