import type { FC } from 'react'
import React from 'react'

import type { IconSuperConnectIosProps } from './IconSuperConnectIos.types'
import * as Styled from './styles/IconSuperConnectIos.style'

import { nonThemeableRules } from '../../ruleSet'

const IconSuperConnectIos: FC<IconSuperConnectIosProps> = ({
  size = nonThemeableRules.defaultSize as number as IconSuperConnectIosProps['size'],
  isResponsive = false,
}: IconSuperConnectIosProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M87.778 10H32.222C19.95 10 10 19.95 10 32.222v55.556C10 100.05 19.95 110 32.222 110h55.556C100.05 110 110 100.051 110 87.778V32.222C110 19.95 100.051 10 87.778 10Z'
          fill='#E60000'
        />
        <path
          d='M60 86.83a4.288 4.288 0 1 0 0-8.578 4.288 4.288 0 0 0 0 8.577Zm23.285-18.887c-.47 0-.95-.105-1.402-.319L60 57.268 38.117 67.624a3.286 3.286 0 0 1-2.81-5.94l23.279-11.017a3.338 3.338 0 0 1 2.822 0l23.28 11.017a3.286 3.286 0 0 1 1.564 4.375 3.29 3.29 0 0 1-2.973 1.884h.006Z'
          fill='#fff'
        />
        <path
          d='M56.587 58.097c2.237-1.059 4.508-1.057 6.815.005l-2.341-2.567-2.962-.417-1.506 2.973-.006.006Zm-27.655.469a4.923 4.923 0 0 1-4.457-2.822 4.924 4.924 0 0 1 2.348-6.56L57.89 34.475a4.922 4.922 0 0 1 6.56 2.347 4.924 4.924 0 0 1-2.347 6.56l-31.067 14.71a4.861 4.861 0 0 1-2.104.475Z'
          fill='#fff'
        />
        <path
          d='M91.068 58.566a4.85 4.85 0 0 1-2.103-.475l-31.07-14.708a4.924 4.924 0 0 1-2.347-6.56 4.924 4.924 0 0 1 6.56-2.348l31.069 14.709a4.93 4.93 0 0 1-2.11 9.383Z'
          fill='#fff'
        />
        <path
          d='M82.056 54.3a4.926 4.926 0 0 1-4.925-4.925v-8.606a4.926 4.926 0 1 1 9.852 0v8.606a4.926 4.926 0 0 1-4.927 4.926Zm-25.463-9.486c2.236-1.059 4.508-1.057 6.815.006l-2.342-2.567-2.96-.418-1.508 2.973-.005.006Zm17.901 31.827c-.412 0-.829-.087-1.229-.278L60 70.087l-13.265 6.276a2.879 2.879 0 0 1-3.83-1.367 2.882 2.882 0 0 1 1.367-3.831l14.488-6.856a2.92 2.92 0 0 1 2.469 0l14.488 6.856a2.875 2.875 0 0 1-1.235 5.476h.012Z'
          fill='#fff'
        />
        <path d='M56.587 70.991c2.237-1.059 4.508-1.057 6.815.006L61.06 68.43l-2.962-.418-1.506 2.973-.006.006Z' fill='#fff' />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconSuperConnectIos
