import type { FC } from 'react'
import React from 'react'

import type { IconVodafoneLightProps } from './IconVodafoneLight.types'
import * as Styled from './styles/IconVodafoneLight.style'

import { nonThemeableRules } from '../../ruleSet'

const IconVodafoneLight: FC<IconVodafoneLightProps> = ({
  size = nonThemeableRules.defaultSize as number as IconVodafoneLightProps['size'],
  isResponsive = false,
}: IconVodafoneLightProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10 42c0-11.201 0-16.802 2.18-21.08a19.999 19.999 0 0 1 8.74-8.74C25.198 10 30.8 10 42 10h36c11.201 0 16.802 0 21.08 2.18a19.997 19.997 0 0 1 8.74 8.74C110 25.198 110 30.8 110 42v36c0 11.201 0 16.802-2.18 21.08a19.995 19.995 0 0 1-8.74 8.74C94.802 110 89.2 110 78 110H42c-11.201 0-16.802 0-21.08-2.18a19.997 19.997 0 0 1-8.74-8.74C10 94.802 10 89.2 10 78V42Z'
          fill='#fff'
        />
        <g clipPath='url(#5786e642-b1e5-4fda-93ac-86f4c262238d__a)'>
          <path d='M60.5 98C81.21 98 98 81.21 98 60.5S81.21 23 60.5 23 23 39.79 23 60.5 39.79 98 60.5 98Z' fill='#E60000' />
          <path
            d='M60.791 81.413c-10.303.034-21.024-8.76-21.07-22.881-.023-9.338 5.015-18.326 11.455-23.67 6.281-5.202 14.886-8.539 22.69-8.565 1.006 0 2.057.08 2.7.299-6.824 1.415-12.256 7.765-12.232 14.97-.001.205.014.41.045.611 11.42 2.781 16.603 9.671 16.634 19.204.032 9.533-7.493 19.99-20.222 20.032Z'
            fill='#fff'
          />
        </g>
        <defs>
          <clipPath id='5786e642-b1e5-4fda-93ac-86f4c262238d__a'>
            <path d='M0 0h75v75H0z' fill='#fff' transform='translate(23 23)' />
          </clipPath>
        </defs>
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconVodafoneLight
