import type { FC } from 'react'
import React from 'react'

import type { IconMailandcloudIosProps } from './IconMailandcloudIos.types'
import * as Styled from './styles/IconMailandcloudIos.style'

import { nonThemeableRules } from '../../ruleSet'

const IconMailandcloudIos: FC<IconMailandcloudIosProps> = ({
  size = nonThemeableRules.defaultSize as number as IconMailandcloudIosProps['size'],
  isResponsive = false,
}: IconMailandcloudIosProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M87.778 10H32.222C19.95 10 10 19.95 10 32.222v55.556C10 100.05 19.95 110 32.222 110h55.556C100.05 110 110 100.051 110 87.778V32.222C110 19.95 100.051 10 87.778 10Z'
          fill='#E60000'
        />
        <path
          d='M97 63.67a14.974 14.974 0 0 0-11.96-14.662 20.051 20.051 0 0 0-38.272-10.11 20.047 20.047 0 0 0-10.58 38.534v8.641a5.987 5.987 0 0 0 5.979 5.978h35.692a5.987 5.987 0 0 0 5.997-5.978v-7.545A14.974 14.974 0 0 0 97 63.67Zm-55.715-4.06H78.74a2.083 2.083 0 0 1 2.064 1.899v1.752l-17.67 10.915a6.45 6.45 0 0 1-3.131 1.037 6.416 6.416 0 0 1-3.12-1.037L39.22 63.26v-1.75a2.079 2.079 0 0 1 2.065-1.898ZM77.86 89H42.167a2.95 2.95 0 0 1-2.921-2.921V66.932l17.67 10.91a6.372 6.372 0 0 0 3.087 1.041 6.372 6.372 0 0 0 3.12-1.042l17.671-10.909v19.126a2.955 2.955 0 0 1-2.935 2.94Z'
          fill='#fff'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconMailandcloudIos
