import type { FC } from 'react'
import React from 'react'

import type { IconNetflixWordmarkProps } from './IconNetflixWordmark.types'
import * as Styled from './styles/IconNetflixWordmark.style'

import { nonThemeableRules } from '../../ruleSet'

const IconNetflixWordmark: FC<IconNetflixWordmarkProps> = ({
  size = nonThemeableRules.defaultSize as number as IconNetflixWordmarkProps['size'],
  isResponsive = false,
}: IconNetflixWordmarkProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10 42c0-11.201 0-16.802 2.18-21.08a19.999 19.999 0 0 1 8.74-8.74C25.198 10 30.8 10 42 10h36c11.201 0 16.802 0 21.08 2.18a19.997 19.997 0 0 1 8.74 8.74C110 25.198 110 30.8 110 42v36c0 11.201 0 16.802-2.18 21.08a19.995 19.995 0 0 1-8.74 8.74C94.802 110 89.2 110 78 110H42c-11.201 0-16.802 0-21.08-2.18a19.997 19.997 0 0 1-8.74-8.74C10 94.802 10 89.2 10 78V42Z'
          fill='#000'
        />
        <path
          d='M81.939 68.455c1.112.086 2.223.177 3.332.275V49.168h-3.332v19.287Zm-53.6-7.258L24 49.168h-3.166v21.257c1.109-.158 2.22-.309 3.332-.454v-11.56l3.859 11.085c1.213-.14 2.429-.274 3.647-.4V49.169H28.34v12.03Zm6.536 7.59a280.88 280.88 0 0 1 9.255-.688v-3.283c-1.98.114-3.954.25-5.923.405v-4.97c1.292-.013 2.992-.053 4.5-.034v-3.283c-1.204-.003-3.14.016-4.5.034v-4.487h5.923V49.17h-9.255v19.618Zm11.391-16.306h3.487v15.352a287.11 287.11 0 0 1 3.332-.106V52.481h3.487v-3.313H46.266v3.313Zm12.442 15.157 3.332-.001v-7.58h4.517v-3.282H62.04V52.48h5.97v-3.31h-9.302v18.469Zm41.248-18.47h-3.66l-2.412 5.592-2.168-5.592H88.11l3.873 9.992-4.228 9.803c1.17.114 2.337.235 3.502.364l2.46-5.7 2.435 6.283c1.269.163 2.534.334 3.797.514l.005-.002-4.34-11.195 4.34-10.059Zm-26.479-.001h-3.332V67.8c3.028.104 6.043.256 9.046.457v-3.283c-1.9-.127-3.804-.234-5.715-.323V49.167h.001Z'
          fill='#E50914'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconNetflixWordmark
