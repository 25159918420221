import type { FC } from 'react'
import React from 'react'

import type { IconSpotifyProps } from './IconSpotify.types'
import * as Styled from './styles/IconSpotify.style'

import { nonThemeableRules } from '../../ruleSet'

const IconSpotify: FC<IconSpotifyProps> = ({
  size = nonThemeableRules.defaultSize as number as IconSpotifyProps['size'],
  isResponsive = false,
}: IconSpotifyProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10 42c0-11.201 0-16.802 2.18-21.08a19.999 19.999 0 0 1 8.74-8.74C25.198 10 30.8 10 42 10h36c11.201 0 16.802 0 21.08 2.18a19.997 19.997 0 0 1 8.74 8.74C110 25.198 110 30.8 110 42v36c0 11.201 0 16.802-2.18 21.08a19.995 19.995 0 0 1-8.74 8.74C94.802 110 89.2 110 78 110H42c-11.201 0-16.802 0-21.08-2.18a19.997 19.997 0 0 1-8.74-8.74C10 94.802 10 89.2 10 78V42Z'
          fill='#1A1414'
        />
        <path
          d='M81.216 55.93c-11.56-6.867-30.633-7.5-41.67-4.148a3.355 3.355 0 1 1-1.948-6.42c12.67-3.847 33.731-3.103 47.041 4.799a3.355 3.355 0 1 1-3.423 5.769Zm-.378 10.17a2.8 2.8 0 0 1-3.847.92c-9.64-5.925-24.338-7.64-35.74-4.179a2.801 2.801 0 0 1-3.491-1.863 2.8 2.8 0 0 1 1.866-3.487c13.027-3.953 29.22-2.04 40.292 4.765a2.797 2.797 0 0 1 .92 3.843Zm-4.389 9.767a2.232 2.232 0 0 1-3.073.742c-8.424-5.149-19.025-6.31-31.509-3.457a2.236 2.236 0 1 1-.995-4.359c13.662-3.124 25.383-1.78 34.837 3.998a2.239 2.239 0 0 1 .74 3.076ZM59.999 24.13c-19.81 0-35.87 16.059-35.87 35.867 0 19.81 16.06 35.869 35.87 35.869 19.808 0 35.868-16.058 35.868-35.869 0-19.807-16.058-35.867-35.868-35.867Z'
          fill='#1FD661'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconSpotify
