import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { getIconSize } from '@vfuk/core-icon'

import type { StyledIconZiggoProps } from './IconZiggo.style.types'

import ruleSet from '../../../ruleSet'

export const IconWrapper = styled.span<StyledIconZiggoProps>((props: StyledIconZiggoProps): FlattenSimpleInterpolation => {
  const localTheme = ruleSet()

  return css`
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    span {
      display: inline-flex;
    }

    svg {
      ${getIconSize(props.isResponsive, localTheme.size[props.size!], localTheme.widthOverride)}
    }
  `
})
