import type { FC } from 'react'
import React from 'react'

import type { IconSecurenetIosProps } from './IconSecurenetIos.types'
import * as Styled from './styles/IconSecurenetIos.style'

import { nonThemeableRules } from '../../ruleSet'

const IconSecurenetIos: FC<IconSecurenetIosProps> = ({
  size = nonThemeableRules.defaultSize as number as IconSecurenetIosProps['size'],
  isResponsive = false,
}: IconSecurenetIosProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M87.778 10H32.222C19.95 10 10 19.95 10 32.222v55.556C10 100.05 19.95 110 32.222 110h55.556C100.05 110 110 100.051 110 87.778V32.222C110 19.95 100.051 10 87.778 10Z'
          fill='url(#308bfa42-5ab6-45f4-be7d-c0d113856bbd__a)'
        />
        <path
          d='M61.883 27.684a3.02 3.02 0 0 0-3.21 0C37.826 40.758 25 29.318 25 35.855c0 44.363 28.88 59.291 34.379 61.732a2.21 2.21 0 0 0 1.797 0c5.5-2.44 34.38-17.37 34.38-61.732 0-6.56-12.828 4.903-33.673-8.17Z'
          fill='#fff'
          fillRule='evenodd'
        />
        <defs>
          <linearGradient id='308bfa42-5ab6-45f4-be7d-c0d113856bbd__a' gradientUnits='userSpaceOnUse' x1='60' x2='60' y1='12.041' y2='110'>
            <stop stopColor='#E60000' />
            <stop offset='.516' stopColor='#E40000' />
            <stop offset='.796' stopColor='#D00000' />
            <stop offset='.929' stopColor='#BE0000' />
            <stop offset='1' stopColor='#B20000' />
          </linearGradient>
        </defs>
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconSecurenetIos
