import type { FC } from 'react'
import React from 'react'

import type { IconVodafoneProps } from './IconVodafone.types'
import * as Styled from './styles/IconVodafone.style'

import { nonThemeableRules } from '../../ruleSet'

const IconVodafone: FC<IconVodafoneProps> = ({
  size = nonThemeableRules.defaultSize as number as IconVodafoneProps['size'],
  isResponsive = false,
}: IconVodafoneProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path d='M60 110c27.614 0 50-22.386 50-50S87.614 10 60 10 10 32.386 10 60s22.386 50 50 50Z' fill='#E60000' />
        <path
          d='M60.388 87.884c-13.737.046-28.032-11.68-28.092-30.509-.032-12.45 6.685-24.434 15.272-31.56 8.375-6.935 19.848-11.384 30.255-11.42 1.34 0 2.74.107 3.598.4-9.098 1.887-16.34 10.353-16.31 19.96 0 .273.02.545.06.815 15.227 3.708 22.138 12.894 22.18 25.605.043 12.71-9.99 26.653-26.963 26.709Z'
          fill='#fff'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconVodafone
